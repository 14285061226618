import axios from 'axios'
export const documentPerPage = 10

export const queryDocuments = async params => {
  const accessToken = localStorage.getItem('accessToken')
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
    params: {
      limit: documentPerPage,
      ...params
    }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents`, config)
    return { error: null, data: response.data }
  } catch (error) {
    return {
      error: {
        message: error.message,
        status: error.response && error.response.status
      }
    }
  }
}
