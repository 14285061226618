import { createSlice } from '@reduxjs/toolkit'
import { logout, session } from '../../API/session/index' // Test without index
const defaultValue = null

export const userSlice = createSlice({
  initialState: {
    error: defaultValue,
    user: defaultValue
  },
  name: 'user',
  reducers: {
    reset: state => {
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
      state.user = defaultValue
      state.error = defaultValue
    },
    set: (state, action) => {
      const { accessToken, user } = action.payload
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('user', JSON.stringify(user))
      state.user = user
    },
    setError: (state, action) => {
      state.error = action.payload
      state.user = defaultValue
    }
  }
})

export const { reset, set, setError } = userSlice.actions

export const authenticate = (username, password) => async dispatch => {
  try {
    const user = await session({ password, username })
    dispatch(set(user))
  } catch (error) {
    dispatch(setError(error.message))
  }
}

export const signOut = () => async dispatch => {
  try {
    await logout()
    dispatch(reset())
  } catch (error) {
    dispatch(setError(error.message))
  }
}

export const selectUser = state => {
  return JSON.parse(localStorage.getItem('user'))
}

export const selectError = state => state.user.error

export default userSlice.reducer
