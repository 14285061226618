import { Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { connect } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InputBase from '@material-ui/core/InputBase'
import React, { useState, useEffect } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Paging from './Paging'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'
import useStyles from './searchBarStyle'
import { documentPerPage } from './../../adapters/API/documents'
import DateRangeSelector from './DateRangeSelector'
import { selectUser } from '../../adapters/redux/reducers/userSlice'

const dateRangeDefaultValue = [null, null]

function SearchBar ({ onSearch, user }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [dateRange, setDateRange] = React.useState(dateRangeDefaultValue)
  const [pageNumber, setPageNumber] = React.useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const dispatcherRole = 3
  const isDispatcher = user.role === dispatcherRole

  const handleClearSearchField = () => {
    setSearchTerm('')
    resetPaging()
    onSearch(getQueryParams({ q: '' }))
  }
  const handleClearDateFilter = () => {
    setDateRange(dateRangeDefaultValue)
    setShowDateRangePicker(false)
  }
  const handlePagingChanges = newPageNumber => {
    setPageNumber(newPageNumber)
    const offset = (newPageNumber - 1) * documentPerPage
    onSearch(getQueryParams({ offset }))
  }

  const getQueryParams = overrideParams => {
    const queryParams = {}
    if (searchTerm) {
      queryParams.q = searchTerm
    }
    if (dateRange[0]) {
      queryParams['recordedAt[gte]'] = dateRange[0]
      queryParams['recordedAt[lt]'] = dateRange[1]
    }
    return {
      ...queryParams,
      ...overrideParams
    }
  }

  const resetPaging = () => {
    if (pageNumber !== 1) {
      setPageNumber(1)
    }
  }

  const queryWithPageReset = () => {
    resetPaging()
    onSearch(getQueryParams())
  }

  useEffect(() => {
    const [from, to] = dateRange
    if ((from && to) || (!from && !to)) {
      queryWithPageReset()
    }
  }, [dateRange])

  const onTextChange = e => setSearchTerm(e.target.value)

  const onKeyPress = event => event.keyCode === 13 && queryWithPageReset()

  const handleSearch = () => {
    onSearch(getQueryParams())
    resetPaging()
  }

  const isDateRangeSet = dateRange[0] && dateRange[1]
  const datePickerTooltip = isDateRangeSet ? `${dateRange[0].toLocaleDateString()}-${dateRange[1].toLocaleDateString()}` : t('documents.dateFilterTooltip')
  const dateRangeSetStyle = isDateRangeSet ? 'bold' : null
  const dateRangeActiveStyle = showDateRangePicker ? classes.active : null

  return (
    <>
      <div className={classes.searchControl}>
        <div className={classes.search}>
          <Button onClick={handleSearch} className={classes.button}>
            <SearchIcon />
          </Button>
          <InputBase
            inputProps={{ 'aria-label': 'naked' }}
            onChange={onTextChange}
            onKeyDown={onKeyPress}
            placeholder={t(`documents.${isDispatcher ? 'dispatcherSearchPlaceholder' : 'searchPlaceholder'}`)}
            type="text"
            value={searchTerm}
          />
          {searchTerm &&
            <Button visibility="hidden" onClick={handleClearSearchField} className={classes.button}>
              <CloseIcon />
            </Button>}
        </div>
        <div className={clsx(classes.filter, 'bgInverse')}>
          <Tooltip title={datePickerTooltip}>
            <Button
              endIcon={<ExpandMoreIcon />}
              onClick={() => setShowDateRangePicker(!showDateRangePicker)}
              className={clsx(classes.button, dateRangeSetStyle, dateRangeActiveStyle)}
            >
              {t('documents.btnDateFilter')}
            </Button>
          </Tooltip>
          <Button onClick={handleClearDateFilter} className={classes.button}>
            {t('documents.bntClear')}
          </Button>
        </div>
        <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} visible={showDateRangePicker} />
      </div>
      <Paging pageNumber={pageNumber} setPageNumber={handlePagingChanges} />
    </>
  )
}

const mapStateToProps = state => ({ user: selectUser(state) })

export default connect(mapStateToProps)(SearchBar)
