import { Chip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React from 'react'

const statusToColor = {
  delivered: '#90BC54',
  in_transit: '#6BBEDC',
  new: '#FCB813'
}

const badgeColor = status => ({ backgroundColor: statusToColor[status] })

export default function DocumentStatus ({ status }) {
  const { t } = useTranslation()
  return (
    <Chip
      className="statusClip"
      label={t(`documents.status.${status}`)}
      style={badgeColor(status)}
      size="small"
    />)
}
