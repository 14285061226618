import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: '#fbd6c9',
    border: '1px solid transparent',
    borderColor: '#f7b39a',
    borderRadius: 3,
    color: '#e64a12',
    fontFamily: 'Open Sans,sans-serif',
    fontSize: '1em',
    fontWeight: 400,
    marginBottom: 20,
    padding: 20,
    textAlign: 'left'
  }
}))

export default useStyles
