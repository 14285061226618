export const dateTimeWithTimeZone = dateString => {
  const date = new Date(dateString)
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZoneName: 'short'
  }
  let nth = 0
  return date
    .toLocaleDateString('en-US', options)
    .replace(/,/g, match => (nth++ === 1) ? '' : match)
}
