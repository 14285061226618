import { queryDocumentById, getAttachments } from '../../API/documentsDetails'
import { createSlice } from '@reduxjs/toolkit'
import { reset as resetUser } from './userSlice'
const defaultValue = null

export const documentDetailsSlice = createSlice({
  initialState: {
    attachment: defaultValue,
    document: defaultValue
  },
  name: 'documentDetails',
  reducers: {
    setDocument: (state, action) => {
      const { error, data } = action.payload
      state.document = (data && data.document) || defaultValue
      state.error = error && error.message
    },
    setAttachment: (state, action) => {
      const { error, url } = action.payload
      state.attachment = url
      state.error = error && error.message
    }
  }
})

export const queryDocument = id => async (dispatch) => {
  const { error, data } = await queryDocumentById(id)
  if (error && error.status === 401) {
    dispatch(resetUser())
  }
  dispatch(setDocument({ error, data }))
}

export const queryAttachment = id => async (dispatch) => {
  const { error, data } = await getAttachments(id)
  if (error && error.status === 401) {
    dispatch(resetUser())
  }
  const url = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
  dispatch(setAttachment({ error, url }))
}

export const { reset, setDocument, setAttachment } = documentDetailsSlice.actions

export const selectDocument = state => state.documentDetails.document

export const selectAttachment = state => state.documentDetails.attachment

export default documentDetailsSlice.reducer
