import './session.css'
import { Box, Button, Grid, Hidden, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { authenticate, selectError, selectUser } from '../../adapters/redux/reducers/userSlice'
import { connect, useDispatch } from 'react-redux'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { ReactComponent as PayLoadLogo } from '../Images/logo.svg'
import { Redirect } from 'react-router-dom'
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider'
import clsx from 'clsx'
import useStyles from './sessionStyle'
import { useTranslation } from 'react-i18next'

function Login ({ error, user }) {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const [phoneNumber, setphoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [isValid, setisValid] = useState(true)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onSubmit = event => {
    event.preventDefault()
    if (!phoneNumber || !password) {
      setisValid(false)
      return
    }
    dispatch(authenticate(phoneNumber, password))
    setisValid(true)
  }

  if (user) {
    return (
      <Redirect to="/documents" />
    )
  }
  return (
    <StyledEngineProvider injectFirst>
      <Box>
        <Hidden xlDown>
          <Box className= {clsx(classes.contours, classes.bg)}/>
        </Hidden>

        <Box
          display = 'absolute'
          className={classes.logo}
          height={52}
        >
          <Grid
            container
            justifyContent='space-evenly'
            alignItems='center'
          >
            <Grid
              item
              xs={12}
              sm={8}
              xl={12}
              className={classes.payLoad}>
              <PayLoadLogo />
            </Grid>
          </Grid>
        </Box>

        <Box
          display='flex'
          flexGrow='1'
          justifyContent='stretch'
          className={classes.page}
        >
          <Grid
            container
            justifyContent='space-evenly'
            alignItems='center'
            minHeight= {650}
          >
            <Grid
              item
              xs={12}
              sm={8}
              xl={6}
            >
              <Box className={classes.loginForm}>
                <Box className={classes.formHeading}>
                  <h1 className={classes.formTitle}>{t('login.title')}</h1>
                </Box>
                <Box className={classes.form}>
                  {
                    (error || !isValid) &&
                    <ErrorMessage message={t('login.errorMessage')} />
                  }

                  <form autoComplete='off' onSubmit={onSubmit}>
                    <InputLabel
                      htmlFor="mobile"
                      className={classes.formLabel}
                    >
                      {t('login.labelUserName')}
                    </InputLabel>
                    <div className={classes.inputBox}>
                      <TextField
                        fullWidth
                        className={classes.txtField}
                        id='mobile'
                        placeholder={t('login.userNamePlaceHolder')}
                        variant='outlined'
                        value={phoneNumber}
                        onInput={e => setphoneNumber(e.target.value)}
                      />
                    </div>
                    <InputLabel
                      htmlFor="password"
                      className={classes.formLabel}>
                      {t('login.labelPassword')}
                    </InputLabel>
                    <div className={classes.inputBox}>
                      <OutlinedInput
                        id='password'
                        className={classes.txtField}
                        notched={true}
                        fullWidth
                        placeholder={t('login.passwordPlaceHolder')}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onInput={e => setPassword(e.target.value)}
                        onChange={(event) => setPassword(event.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              className={classes.showHidePassword}
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <span>{showPassword ? t('login.btnHide') : t('login.btnShow')}</span>
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div>

                    <Button
                      type='submit'
                      variant="contained"
                      className={classes.signInBtn}
                    >
                      Sign In
                    </Button>
                  </form>
                </Box>
              </Box>
            </Grid>

            <Hidden xlDown>
              <Grid
                item
                xs={6}
              >
                <Box className={classes.introSection}>
                  <hr className={classes.introblueBar} />
                  <h1 className={classes.introMessage}>
                    {t('login.message')}
                  </h1>
                  <Button
                    className= {classes.introBtn}
                    variant="outlined"
                    color="primary"
                    target="_blank"
                    href="https://ezops.ca/payload/#manifest-section">
                    {t('login.learnMore')}
                  </Button>
                </Box>
              </Grid>
            </Hidden>
            <div className="bottom-bar">
              <hr className="color-bar" />
            </div>
          </Grid>
        </Box>
      </Box>
    </StyledEngineProvider>
  )
}

const mapStateToProps = state => ({ error: selectError(state), user: selectUser(state) })

export default connect(mapStateToProps)(Login)
