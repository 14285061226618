import React from 'react'
import Box from '@material-ui/core/Box'
import DateFnsAdapter from '@material-ui/lab/AdapterDateFns'
import TextField from '@material-ui/core/TextField'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import StaticDateRangePicker from '@material-ui/lab/StaticDateRangePicker'
import useStyles from './searchBarStyle'

export default function BasicDateRangePicker ({ visible, dateRange, setDateRange }) {
  const classes = useStyles()

  return ( // TODO maybe can drop () and <>
    <>
      {visible &&
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <StaticDateRangePicker
            className={classes.datePicker}
            displayStaticWrapperAs="desktop"
            value={dateRange}
            onChange={setDateRange}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} variant="standard" />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} variant="standard" />
              </>
            )}
          />
        </LocalizationProvider>}
    </>
  )
}
