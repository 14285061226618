export default {
  translation: {
    documents: {
      bntClear: 'Clear',
      btnDateFilter: 'Shipped date',
      dateFilterTooltip: 'Select a date interval',
      dispatcherSearchPlaceholder: 'Search by waybill number, truck/trailer number, generator, or status',
      generator: 'Generator',
      receiver: 'Receiver',
      searchPlaceholder: 'Search by waybill number, truck/trailer number, generator, transporter, or status',
      status: {
        delivered: 'Delivered',
        in_transit: 'In Transit',
        new: 'Pickup'
      },
      tdgDocument: 'TDG Document',
      transporter: 'Transporter'
    },
    login: {
      btnHide: 'Hide',
      btnShow: 'Show',
      btnSignIn: 'Sign In',
      btnSignOut: 'Sign Out',
      errorMessage: 'Invalid username or password',
      header: 'Please Log In',
      labelPassword: 'Password',
      labelUserName: 'Email or mobile phone number',
      learnMore: 'Learn more',
      message: 'Remain compliant in the transportation of waste and recyclables from origin through to destination.',
      passwordPlaceHolder: 'Enter your password',
      title: 'Sign in to Payload TDG',
      userNamePlaceHolder: 'Enter your email or mobile number (eg: 8005167647)'
    },
    navigation: {
      btnDocuments: 'Documents',
      btnTDG: 'TDG'
    }
  }
}
