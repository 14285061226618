import axios from 'axios'

export const queryDocumentById = async id => {
  const accessToken = localStorage.getItem('accessToken')
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/${id}`, config)
    return { error: null, data: response.data }
  } catch (error) {
    return {
      error: {
        message: error.message,
        status: error.response && error.response.status
      }
    }
  }
}

export const getAttachments = async id => {
  const accessToken = localStorage.getItem('accessToken')
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'blob'
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/${id}/attachment`, config)
    return { error: null, data: response.data }
  } catch (error) {
    return {
      error: {
        message: error.message,
        status: error.response && error.response.status
      }
    }
  }
}
