import Button from '@material-ui/core/Button'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PayLoadLogo } from '../../Images/logoSmall.svg'
import { selectUser, signOut } from '../../../adapters/redux/reducers/userSlice'
import useStyles from './headerStyle'

function Header ({ user }) {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const onOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSignOut = () => {
    dispatch(signOut())
    history.push('/login')
  }

  return (
    <>
      <div className={classes.header}>
        <PayLoadLogo className={clsx(classes.logo, 'left')} />
        <Button
          onClick={() => history.push('/documents')}
          className={clsx(classes.navButton, 'left')}
        >
          {t('navigation.btnDocuments')}
        </Button>

        <div>
          <Button
            className={clsx(classes.navButtonUser, 'right')}
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={onOpenUserMenu}
            endIcon={<ExpandMoreIcon />}
          >
            {`${user.firstName} ${user.lastName}`}
          </Button>
          <Menu
            className={classes.userMenu}
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={onSignOut}>
              {t('login.btnSignOut')}
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className={classes.subNavigation}>
        <label className={clsx(classes.subNavNavButton, 'text')}> {t('navigation.btnTDG')} </label>
      </div>
    </>
  )
}

const mapStateToProps = state => ({ user: selectUser(state) })

export default connect(mapStateToProps)(Header)
