import { createSlice } from '@reduxjs/toolkit'
import { queryDocuments } from '../../API/documents'
import { reset as resetUser } from './userSlice'

export const documentsSlice = createSlice({
  initialState: {
    documents: [],
    count: 0
  },
  name: 'documents',
  reducers: {
    set: (state, action) => {
      const { error, data } = action.payload
      state.count = (data && data.count) || 0
      state.documents = (data && data.documents) || []
      state.error = error && error.message
    }
  }
})

export const query = params => async (dispatch) => {
  const { error, data } = await queryDocuments(params)
  if (error && error.status === 401) {
    dispatch(resetUser())
  }
  dispatch(set({ error, data }))
}

export const { reset, set } = documentsSlice.actions

export const selectDocuments = state => state.documents.documents

export const selectDocumentCount = state => state.documents.count

export default documentsSlice.reducer
