import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './reducers/counterSlice'
import documentDetailsSlice from './reducers/documentDetailsSlice'
import documentsSlice from './reducers/documentsSlice'

import userReducer from './reducers/userSlice'

export default configureStore({
  reducer: {
    counter: counterReducer,
    documentDetails: documentDetailsSlice,
    documents: documentsSlice,
    user: userReducer
  }
})
