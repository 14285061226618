// usage with functional or higher order components: https://react.i18next.com/guides/quick-start
import enCa from './en-ca'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: enCa
}

i18n
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false
    },
    keySeparator: '.',
    lng: 'en',
    resources
  })

export default i18n
