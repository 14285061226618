import axios from 'axios'

export const session = async (data) => {
  const driverRoleId = 0
  const apiRoleId = 2
  const disallowedRoles = [driverRoleId, apiRoleId]
  const userData = await axios.post(`${process.env.REACT_APP_API_URL}/session/authenticate`, data)
  if (userData) {
    const { accessToken, user } = userData.data
    if (disallowedRoles.includes(user.role)) {
      throw Error('Unauthorized')
    }
    return { accessToken, user }
  }
}

export const logout = async () => {
  const accessToken = localStorage.getItem('accessToken')
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  }

  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/session/logout`, {}, config)
  } catch (error) {
    return {
      error: {
        message: error.message,
        status: error.response && error.response.status
      }
    }
  }
}
