import { makeStyles } from '@material-ui/styles'

const searchBarStyle = makeStyles(theme => ({
  active: {
    color: '#01A0CF !important'
  },
  button: {
    fontSize: '12px !important',
    lineHeight: '16px !important',
    textTransform: 'none !important',
    '&.css-1e6y48t-MuiButtonBase-root-MuiButton-root': {
      color: 'black'
    },
    '&.bold': {
      fontWeight: 'bold'
    }
  },
  datePicker: {
    '& .MuiDateRangePickerViewDesktop-rangeCalendarContainer': {
      border: 'none'
    },
    '& .MuiDateRangePickerViewDesktop-root': {
      position: 'absolute',
      backgroundColor: 'white',
      border: '1px solid #cfcfcc',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
      marginLeft: '44.8%',
      [theme.breakpoints.down('lg')]: {
        marginLeft: '20%'
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      [theme.breakpoints.down('sm')]: {
        width: '300px',
        '&.MuiDateRangePickerViewDesktop-rangeCalendarContainer': {
          border: 'none'
        }
      }
    }
  },
  filter: {
    backgroundColor: 'white',
    borderTop: '1px solid #e0e0de',
    boxSizing: 'border-box',
    color: 'black',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '37px'
  },
  search: {
    backgroundColor: 'white',
    border: 'none !important',
    display: 'flex!important',
    height: '62px',
    '& .MuiInputBase-root': {
      width: '100%'
    }
  },
  searchControl: {
    border: '1px solid #C6C6C6',
    marginBottom: '20px',
    position: 'sticky',
    top: '0px',
    width: '100%',
    minWidth: '500px'
  },
  pagination: {
    minWidth: '500px'
  }
}))

export default searchBarStyle
