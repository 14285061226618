import './DocumentsDetails.css'
import { Button, Card } from '@material-ui/core'
import { Document, Page, pdfjs } from 'react-pdf'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { queryDocument, selectAttachment, selectDocument } from '../../adapters/redux/reducers/documentDetailsSlice'
import DocumentStatus from '../../Components/Documents/DocumentStatus'
import Header from '../Common/Header'
import { Redirect } from 'react-router-dom'
import { dateTimeWithTimeZone } from '../../utils/dateFormatter'
import { getAttachments } from '../../adapters/API/documentsDetails'
import { selectUser } from '../../adapters/redux/reducers/userSlice'
import { useTranslation } from 'react-i18next'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

function DocumentsDetails ({ match, user, document, attachment }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)

  const downloadPdf = async () => {
    const { data } = await getAttachments(match.params.id) // TODO handle error and 401
    const FileDownload = require('js-file-download')
    FileDownload(data, `${document.waybillNumber}.pdf`)
  }

  useEffect(() => {
    dispatch(queryDocument(match.params.id))
    setIsLoading(false)
  }, [])

  const pdfUrl = {
    url: `${process.env.REACT_APP_API_URL}/documents/${match.params.id}/attachment`,
    httpHeaders: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/pdf'
    }
  }

  if (!user) {
    return <Redirect to="/login" />
  }

  return (
    <div className='container'>
      <Header />
      <Card className="details">
        <div className="info">
          <div className="detailsHeader">
            <div className="detailsTitle left">
              {document && document.waybillNumber}
            </div>
          </div>
          <div className="detailsSubTitle">
            Shipped {document && dateTimeWithTimeZone(document.createdAt) }
          </div>
          <DocumentStatus status={document && document.status} />
        </div>
        <div className="download">
          <Button
            className="pdf-download"
            variant="contained"
            color="primary"
            onClick={downloadPdf}>
            Download
          </Button>
        </div>
      </Card>
      <Card className="pdf">
        <Document
          file={pdfUrl}>
          <Page
            pageNumber={1}
            scale={1.2} >
          </Page>
        </Document>
      </Card>
    </div>
  )
}

const mapStateToProps = state => ({ attachment: selectAttachment(state), document: selectDocument(state), user: selectUser(state) })

export default connect(mapStateToProps)(DocumentsDetails)
