import './documents.css'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { selectUser } from '../../adapters/redux/reducers/userSlice'
import { selectDocuments, query } from '../../adapters/redux/reducers/documentsSlice'
import Header from '../Common/Header'
import SearchBar from './SearchBar'

function Documents ({ documents, user }) {
  const dispatch = useDispatch()

  const onSearch = params => {
    dispatch(query(params))
  }

  if (!user) {
    return <Redirect to="/login" />
  }
  return (
    <div className='container'>
      <Header />
      <SearchBar onSearch={onSearch} />
    </div>)
}

const mapStateToProps = state => ({ documents: selectDocuments(state), user: selectUser(state) })

export default connect(mapStateToProps)(Documents)
