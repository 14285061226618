import { Card, getAccordionDetailsUtilityClass } from '@material-ui/core'
import { Redirect, useHistory } from 'react-router-dom'
import DocumentStatus from './DocumentStatus'
import React from 'react'
import { dateTimeWithTimeZone } from '../../utils/dateFormatter'
import { useTranslation } from 'react-i18next'

const infoLine = (label, value) => (
  <>
    <dt>{label}</dt>
    <dd name={label}> {value}</dd>
  </>
)
export default function DocumentSummary ({ document }) {
  const history = useHistory()
  const { carrierName, consignorName, createdAt, destinationName, id, status, waybillNumber } = document
  const { t } = useTranslation()

  return (
    <Card
      className="summary"
      onClick={() => {
        history.push({ pathname: `/documents/${id}`, ...document })
      }}
    >
      <div>
        <div className="summaryHeader">
          <div className="summaryTitle left">
            {waybillNumber}
          </div>
          <div className="date right">
            {dateTimeWithTimeZone(createdAt)}
          </div>
        </div>
        <div className="summarySubTitle">
          {t('documents.tdgDocument')}
        </div>
        <DocumentStatus status={status} />
        <dl>
          {infoLine(t('documents.generator'), consignorName)}
          {infoLine(t('documents.transporter'), carrierName)}
          {infoLine(t('documents.receiver'), destinationName)}
        </dl>
      </div>
    </Card>
  )
}
