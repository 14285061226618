import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'
import Documents from './Components/Documents/Documents'
import Login from './Components/Session/session'
import DocumentDetails from './Components/DocumentsDetails/DocumentsDetails.jsx'

function App () {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/login"
          render={() => (<Login />)}
        />
        <Route
          exact
          path="/documents"
          render={() => (<Documents />)}
        />
        <Route
          exact
          path="/documents/:id"
          render={(props) => (<DocumentDetails {...props}/>)}
        />
        <Redirect to="/documents" />
      </Switch>
    </Router>
  )
}

export default App
