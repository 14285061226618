import image from '../Images/contoursonblue.png'
import { makeStyles } from '@material-ui/styles'

const titleColor = '#90BC54'
const labelColor = '#3A464C'
const borderColor = '#003F69'
const buttonColor = '#fcb715 !important'
const buttonFontColor = '#4a464c'
const backdropColor = '#f5f5f5'

const useStyles = makeStyles(theme => ({
  bg: {
    bottom: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    width: '50%'
  },
  contours: {
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  form: {
    maxWidth: '27em'
  },
  formHeading: {
    paddingBottom: 98
  },
  formLabel: {
    color: labelColor,
    fontFamily: 'Open Sans,sans-serif',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 'bold'
  },
  formTitle: {
    color: titleColor,
    fontFamily: 'Poppins,Open Sans,sans-serif',
    fontSize: 32,
    fontWeight: 'bold',
    height: 43,
    marginBottom: 0,
    marginTop: 0,
    maxWidth: 298
  },
  inputBox: {
    maxWidth: '27em'
  },
  introBtn: {
    '&:hover': {
      borderColor: titleColor,
      borderRadius: 0,
      borderWidth: 2,
      color: titleColor,
      transition: 'none'
    },
    borderColor: borderColor,
    borderRadius: 0,
    borderWidth: 2,
    color: borderColor,
    fontFamily: 'Open Sans,sans-serif',
    fontWeight: 700,
    textTransform: 'none'
  },
  introMessage: {
    color: titleColor,
    fontFamily: 'Poppins,Open Sans,sans-serif',
    fontWeight: 700,
    marginBottom: 38
  },
  introSection: {
    maxWidth: 380,
    paddingLeft: '8em'
  },
  introblueBar: {
    border: 'solid',
    color: borderColor,
    marginBottom: 10,
    marginLeft: 0,
    width: 87
  },
  loginForm: {
    padding: '1em'
  },
  logo: {
    margin: 'auto',
    marginTop: 33,
    maxWidth: 1160
  },
  page: {
    bottom: 0,
    left: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1160px',
    minWidth: '300px',
    overflow: 'none',
    position: 'absolute',
    right: 0,
    top: 0
  },
  payLoad: {
    paddingLeft: 16
  },
  showHidePassword: {
    backgroundColor: backdropColor,
    borderRadius: 'unset',
    fontFamily: 'Open Sans,sans-serif',
    fontSize: '1em',
    padding: '1em'
  },
  signInBtn: {
    '&:hover': {
      backgroundColor: buttonColor,
      boxShadow: '4px 4px 15px rgb(0 0 0 / 30%)'
    },
    backgroundColor: buttonColor,
    boxShadow: '4px 4px 15px rgb(0 0 0 / 25%)',
    color: buttonFontColor,
    fontFamily: 'Poppins, pen Sans,sans-serif',
    fontSize: 16,
    fontWeight: 700,
    height: 48,
    marginTop: '2em'
  },
  txtField: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderColor: titleColor
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderColor: titleColor
    },
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: 3,
    marginBottom: 26,
    marginTop: 9
  }
}))

export default useStyles
