import './documents.css'
import { selectDocumentCount, selectDocuments } from './../../adapters/redux/reducers/documentsSlice'
import DocumentSummary from './DocumentSummary'
import { Pagination } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { documentPerPage } from './../../adapters/API/documents'
import { selectUser } from './../../adapters/redux/reducers/userSlice'

function Paging ({ count, documents, pageNumber = 1, setPageNumber }) {
  const noOfPages = Math.ceil(count / documentPerPage)
  const handleChange = (event, value) => {
    setPageNumber(value)
  }

  return (
    <div>
      <div>
        {
          documents
            .map(document => (<DocumentSummary document={document} key={document.id} />))
        }
      </div>
      { documents.length !== 0 ? (
        <div className='pagination'>
          <Pagination
            variant='outlined'
            shape='rounded'
            count={noOfPages}
            page={pageNumber}
            onChange={handleChange}
          />
        </div>
      ) : (
        <div className='messageDocuments'>
          <h4> No documents found</h4>
        </div>
      )
      }
    </div>)
}

const mapStateToProps = state => ({ count: selectDocumentCount(state), documents: selectDocuments(state), user: selectUser(state) })

export default connect(mapStateToProps)(Paging)
