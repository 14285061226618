import { Box } from '@material-ui/core'
import React from 'react'
import useStyles from './errorMessageStyle'

function ErrorMessage ({ message }) {
  const classes = useStyles()
  return (<Box className={classes.error}>{message}</Box>)
}

export default ErrorMessage
