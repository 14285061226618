import { makeStyles } from '@material-ui/styles'

const headerStyles = makeStyles(theme => ({
  header: {
    background: '#90BC54',
    height: '50px',
    left: '0px',
    position: 'absolute',
    right: '0px',
    top: '0px',
    minWidth: '525px'
  },
  logo: {
    height: '24px',
    fill: 'white',
    padding: '13px',
    width: '100px'

  },
  navButton: {
    '&.css-1e6y48t-MuiButtonBase-root-MuiButton-root': {
      fontSize: '16px',
      lineHeight: '22px',
      padding: '14px',
      textTransform: 'none',
      color: 'white'
    }
  },
  navButtonUser: {
    '&.MuiButton-root': {
      alignItems: 'center',
      display: 'flex',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '22px',
      padding: '14px',
      textTransform: 'none',
      color: '#FFFFFF'
    }
  },
  subNavigation: {
    background: 'white',
    minWidth: '525px',
    height: '50px',
    left: '0px',
    position: 'absolute',
    right: '0px',
    top: '50px',
    display: 'block',
    borderBottom: '1px solid #e0e0de',
    filter: 'drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.07))'
  },
  subNavNavButton: {
    border: 'none',
    color: 'black',
    display: 'block',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    padding: '14px'
  },
  userMenu: {
    '& .MuiPaper-root': {
      top: '50px !important',
      minWidth: '200px !important',
      right: '0px !important',
      left: 'unset !important'
    }
  }
}))

export default headerStyles
